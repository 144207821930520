.icon-filled {
    transition: transform 0.3s ease;
}

.icon-filled:hover {
    transform: scale(1.2); /* Increase size on hover */
}
.twitter-st0 {
    stroke:#FFFFFF;
    stroke-miterlimit:10;
}
.twitter-st1 { 
    fill:#FFFFFF;
}
.waht-we-do-service-wrap {
    background-color: #fff;
    color: black;
}
.waht-we-do-service-wrap:hover {
    color: #fff;
    background-color: #c784f7;
    border-bottom-left-radius: 28px;
}
.waht-we-do-service-wrap svg {
    stroke: #c784f7;
}
.waht-we-do-service-wrap:hover svg {
    stroke: #fff;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  font-size: 20px;
  color: #fff !important;
  background-color: #6dfe79 !important;
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  border-radius: 4px;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
  background-color: #000 !important;
  color: #fff !important;
}
.ant-carousel .slick-next {
    inset-inline-end: -12px !important;
}
.ant-carousel .slick-prev {
    inset-inline-start: -15px !important;
}
.slick-dots li button {
    background: #6dfe79 !important;
}
.slick-dots li.slick-active button {
    background: #6dfe79 !important;
}