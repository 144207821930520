@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Island+Moments&display=swap');

*{
    scroll-behavior: smooth;
}
.gravity-container {
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    margin: 0px auto;
}
.font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
}
.island-moments {
    font-family: "Island Moments", cursive;
    font-weight: 400;
    font-style: normal;
  }
  
.gravity-transition {
    transition: all 0.5s;
}
.activeStyle {
    color: #c784f7;
}
.nonActiveStyle {
    color: #fff;
}
.scroll-to-top-btn {
    position: fixed;
    bottom: 25px;
    right: 25px;
    background-color: #fff;
    border: 0px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 1000;
    transition: all 0.5s;
}
  
.scroll-to-top-btn:hover {
    background-color: #000;
}



.icon-slide {
    --color: #fff;
    --hover: #000;
}
.icon-slide:hover,
.icon-slide:focus {
  box-shadow: inset 5em 0 0 0 var(--hover);
}

.icon-slide:hover, .icon-slide:focus {
    color: #fff;
}
.icon-slide {
    font: inherit;
    transition: 0.8s;
}


.bnr-btn-slide {
    --color: #fff;
    --hover: #000;
}
.bnr-btn-slide:hover,
.bnr-btn-slide:focus {
  box-shadow: inset 24em 0 0 0 var(--hover);
}

.bnr-btn-slide:hover, .bnr-btn-slide:focus {
    color: #fff;
}
.bnr-btn-slide {
    font: inherit;
    transition: 0.8s;
}

.btn-slide {
    --color: #fff;
    --hover: #c784f7;
}
.btn-slide:hover,
.btn-slide:focus {
  box-shadow: inset 15em 0 0 0 var(--hover);
}

.btn-slide:hover, .btn-slide:focus {
    color: #fff;
}
.btn-slide {
    font: inherit;
    transition: 0.8s;
    border: 2px solid #000;
}

.serv-card-left.animate {
    animation: servCardLeft 2s ease 0s 1 normal forwards;
}
@keyframes servCardLeft {
	0% {
		transform: scale(0.5);
		transform-origin: 0% 50%;
	}
	100% {
		transform: scale(1);
		transform-origin: 0% 50%;
	}
}

.serv-card-center.animate {
    animation: servCardCenter 2s ease 0s 1 normal forwards;
}
@keyframes servCardCenter {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}

.serv-card-right.animate {
    animation: servCardRight 2s ease 0s 1 normal forwards;
}
@keyframes servCardRight {
	0% {
		transform: scale(0.5);
		transform-origin: 100% 50%;
	}
	100% {
		transform: scale(1);
		transform-origin: 100% 50%;
	}
}

.icon-fade-left {
    animation: iconFadeLeft 2s ease 0s 1 normal forwards;
}
@keyframes iconFadeLeft {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}