.avatar-bg1 {
    background-image: url('../../../assests/img/creative-review-avatar.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.avatar-bg2 {
    background-image: url('../../../assests/img/creative-dot-avatar-uae.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}