.outline-heading{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #b9b9ba;
    text-transform: capitalize;
    word-break: break-word;
    font-family: sans-serif;
    font-weight: 700;
    z-index: 1;
}

.footer__collapsable a:after {
  display:block;
  content: '';
  border-bottom: solid 2px #fff;;  
  border-radius: 1px;
  -webkit-transform: scaleX(0);  
          transform: scaleX(0);  
  -webkit-transition: -webkit-transform 250ms ease-in-out;  
  transition: -webkit-transform 250ms ease-in-out;  
  transition: transform 250ms ease-in-out;  
  transition: transform 250ms ease-in-out, -webkit-transform 250ms ease-in-out;
  visibility: hidden;
  text-align: right;
}
.footer__collapsable a:hover:after {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}